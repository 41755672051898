import React from "react";
import "./App.css";
import logo from "./logo2.png"; // Ensure you have the new logo saved as logo.png in your src directory

const App = () => {
  return (
    <div style={{ margin: "0% 20% 20% 20%" }}>
      <header className="header">
        <img src={logo} alt="7 Minutes News Logo" style={{ width: "100%", marginBottom: "20px" }} />
        <p>Customized News Integration for Every Role and Position in your Company</p>
      </header>

      <div>
        <content className="content">
          <section>
            <h2>The Challenge</h2>
            <p>
              <strong>Information Silos:</strong> Employees face an overwhelming amount of disjointed information across multiple platforms.
            </p>
            <p>
              <strong>Relevance:</strong> It's challenging to sift through vast amounts of data to find content relevant to one's specific role and industry.
            </p>
            <p>
              <strong>Time Efficiency:</strong> Time is precious. Reading through various sources to stay informed isn’t feasible during a busy workday.
            </p>
          </section>

          <section>
            <h2>
              <strong>Our Solution:</strong> 7 Minutes Corporate News Platform
            </h2>
            <p>
              <strong>Centralized Access:</strong> One platform to integrate all subscriptions—newspapers, magazines, journals—tailored to each employee's role.
            </p>
            <p>
              <strong>Role-Specific Content:</strong> Our AI-driven algorithm curates a 7-minute news feed that's relevant to the employee's position and interests.
            </p>
            <p>
              <strong>Continuous Learning:</strong> Encourages ongoing professional development and industry awareness by providing targeted insights.
            </p>
          </section>

          <section>
            <h2>Platform Features</h2>
            <p>
              <strong>Custom Aggregation:</strong> Seamless merging of multiple media sources into one coherent news feed.
            </p>
            <p>
              <strong>Intelligent Curation:</strong> Content is not just aggregated but intelligently summarized and presented in bite-sized formats.
            </p>
            <p>
              <strong>User Profiles:</strong> Create individual profiles for each employee, fine-tuning content delivery to their professional needs.
            </p>
            <p>
              <strong>Analytics Dashboard:</strong> Gain insights into content engagement and employee learning trends to optimize knowledge dissemination.
            </p>
          </section>

          <section>
            <h2>Empower Your Team with Tailored Information</h2>
            <p>Our service goes beyond just providing information—it's about equipping your team with the knowledge relevant to their success and the success of your company.</p>
          </section>
        </content>
      </div>
    </div>
  );
};

export default App;
